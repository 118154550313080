<template>
  <vz-dialog
    ref="dialog"
    big
    public
    :title="title"
    :name="name"
    class="vz-feedback-message-modal"
    hide-footer
    @hide="hide"
  >
    <vz-feedback-message-form
      v-if="departmentFeedbackData"
      :data="departmentFeedbackData"
      :department-code="departmentCode"
      :init-data="initData"
      :init-guid="initGuid"
      :isShowType="isShowType"
      @hide="$hideDialog({name})"
    />
  </vz-dialog>
</template>

<script setup lang="ts">
import { FeedbackMessage } from '~/types/forms'
import { getFeedbackForms } from '~~/api/contacts'
import VzDialog from '~/components/ui-kit/vz-dialog.vue';
import {defineAsyncComponent} from 'vue'

const VzFeedbackMessageForm = defineAsyncComponent(() => import('~/components/forms/vz-feedback-message-form.vue'))

const emit = defineEmits(['clear-open-modal-params'])

const props = defineProps({
  title: {
    type: String,
    default: 'Обратная связь'
  },
  name: {
    type: String,
    default: 'feedback-message'
  },
  departmentCode: {
    type: String,
    default: ''
  },
  initData: {
    type: Object,
    default: () => ({})
  },
  initGuid: {
    type: String,
    default: ''
  },
  isShowType: {
    type: Boolean,
    default: true
  }
})

const {$hideDialog, $showDialog} = useNuxtApp()
const dialog = ref()

const feedbackData = ref<FeedbackMessage[]>([])

const departmentFeedbackData = computed(() => {
  const result = feedbackData.value.find(feedback => feedback.code === props.departmentCode)
  if (result && props.departmentCode === 'clientQuestion') {
    if (!result.forms.find(f => f.fmtGuid === 'origin-request')) {
      result.forms.push({
        fmtGuid: 'origin-request',
        name: 'Запрос оригиналов документов',
        elements: [],
        onClick: () => {
          $hideDialog({ name: props.name })
          $showDialog({ name: 'vz-request-document-modal' })
        }
      })
    }

    // Претензия
    const claim = result.forms.find(f => f.fmtGuid === 'cbc93fdb-856b-4b74-a922-6ad823914149')
    if (claim) {
      claim.elements = []
      claim.onClick = () => {
        $hideDialog({ name: props.name })
        $showDialog({ name: 'vz-request-claim-modal' })
      }
    }
  }
  return result
})

function show() {
  dialog.value?.show()
}

function hide() {
  if (props.initGuid?.length) {
    emit('clear-open-modal-params')
  }
}

onMounted(async () => {
  feedbackData.value = await getFeedbackForms()
})

defineExpose({ show })
</script>
